import React from "react";
import styled from 'styled-components';
import SEO from "../components/SEO";

const ContactStyles = styled.div`

  display: flex;
  flex-direction: column;
  align-content: center;

  form {
    margin: 5rem auto;
    width: 65%;
    @media (max-width: 635px) {
        width: 100%;
    }
  }

`;

export default function Contact({ location }) {
  return (
    <>
      {/* eslint-disable-next-line */}
      <SEO location={location} title="Contact" description="If you would like to collaborate on a project, hire me to help design and build your website, or just have a chat, please get in touch." />
      <h2 className="page-heading">contact</h2>
      <ContactStyles>
        <p>If you would like to collaborate on a project, hire me to help design and build your website, or just have a chat, please get in touch.</p>
        <form action="https://app.99inbound.com/api/e/OWBhKPb3" method="POST">
            <label htmlFor="name" className="visually-hidden">name:</label>
            <input id="name" type="text" name="name" placeholder="enter your name" required aria-required ="true" />
            <label htmlFor="email" className="visually-hidden">email:</label>
            <input id="email" type="email" name="email" placeholder="enter your email" required aria-required ="true" />
            <label htmlFor="message" className="visually-hidden">message:</label>
            <textarea name="message" id="message" placeholder="enter your message here" rows="7" required aria-required ="true"></textarea>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                <input type="checkbox" name="ferocious_almond_striped_owl" value="1" tabIndex="-1" autoComplete="on" />
            </div>
            <button aria-label="submit">Submit</button>
        </form>
        <p><span className="highlight">If you prefer email</span>, find the link in the footer. You can also find me on Github where I’m just dipping my toes into the world of open source. </p>
      </ContactStyles>
    </>
  )
}
